angular.module('app')
    .directive('bookingProgress', function () {
        return {
            restrict: 'A',
            controller: "bookingProgressController",
            scope: {
                booking: '=bookingProgress',
                index: '=',
                length: '=',
                removeBooking: '&'
            },
            templateUrl: '/static/templates/booking-progress.html',
            link: function (scope, element) {

            }
        };
    });